import React, { useEffect } from "react"
import { useIntl, Link } from "gatsby-plugin-intl"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, A11y } from "swiper"

import config from "../config";

// Import Swiper styles
import "swiper/css"

import "swiper/css/pagination"

import "../styles/pages/Index.scss"

import Logo from "../assets/images/logo.png"
import Computer from "../assets/images/home-compute1-image.png"
import Graphic from "../assets/images/home-graphic-image.png"
import Time from "../assets/images/home-time-image.png"
import Needs1 from "../assets/images/icons/home-icon-indicators.svg"
import Needs2 from "../assets/images/icons/home-icon-withdrawal.svg"
import Needs3 from "../assets/images/icons/home-icon-support.svg"
import Needs4 from "../assets/images/icons/home-icon-indicators.svg"
import Computer2 from "../assets/images/home-compute2-image.png"
import Operate1 from "../assets/images/icons/home-icon-benefits5.svg"
import Operate2 from "../assets/images/icons/home-icon-benefits1.svg"
import Operate3 from "../assets/images/icons/home-icon-benefits2.svg"
import Operate4 from "../assets/images/icons/home-icon-benefits3.svg"
import Step1 from "../assets/images/step1.png"
import Step2 from "../assets/images/step2.png"
import Step3 from "../assets/images/step3.png"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import TrustPilotWidget from "../components/TrustPilotWidget"

export default function Index() {
  const intl = useIntl()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout>
      <Seo title="Inicio" />
      <main className="home">
        <section className="home__hero">
          <div className="home__container">
            <div className="home__hero-text">
              <h1 className="home__hero-title">
                {intl.formatMessage({ id: "home-hero-title" })}
              </h1>
              <h2 className="home__hero-subtitle">
                {intl.formatMessage({ id: "home-hero-subtitle" })}
              </h2>
              <p className="home__hero-paragraph">
                {intl.formatMessage({ id: "home-hero-paragraph" })}
              </p>
              <span className="home__hero-actions">
                <Link to="/contact" className="home__hero-button">
                  {intl.formatMessage({ id: "home-hero-cta" })}
                </Link>
                <a
                  href={config.auth_pages.signin}
                  target="_blank"
                  without
                  rel="noreferrer"
                  className="home__hero-button home__hero-button--empty"
                >
                  {intl.formatMessage({ id: "home-hero-cta-two" })}
                </a>
              </span>
            </div>
          </div>
        </section>
        <section className="home__intro">
          <div className="home__container">
            <div className="home__intro-grid">
              <div className="home__intro-box home__intro-box-text">
                <h2 className="home__intro-title">
                  {intl.formatMessage({ id: "home-intro-title" })}{" "}
                  <img src={Logo} alt="Logo" className="home__intro-logo" />{" "}
                  {intl.formatMessage({ id: "home-intro-title2" })}
                </h2>
                <p className="home__intro-paragraph">
                  {intl.formatMessage({ id: "home-intro-text" })}
                </p>
              </div>
              <div className="home__intro-box">
                <img src={Computer} alt="" className="home__intro-image" />
              </div>
            </div>
          </div>
        </section>
        <section className="home__revenue">
          <div className="home__container">
            <h2 className="home__revenue-title">
              {intl.formatMessage({ id: "home-revenue-title" })}{" "}
              <span className="home__revenue-title home__revenue-title--bold">
                {intl.formatMessage({ id: "home-revenue-title2" })}{" "}
              </span>{" "}
            </h2>
            <p className="home__revenue-paragraph">
              {intl.formatMessage({ id: "home-revenue-text" })}
            </p>
          </div>
        </section>
        <section className="home__active">
          <div className="home__container">
            <div className="home__active-grid">
              <div className="home__active-box">
                <img
                  src={Graphic}
                  alt="Graphic"
                  className="home__active-image"
                />
              </div>
              <div className="home__active-box">
                <h3 className="home__active-title">
                  {intl.formatMessage({ id: "home-active-title" })}
                </h3>
                <p className="home__active-paragraph">
                  {intl.formatMessage({ id: "home-active-text" })}
                </p>
                <ul className="home__active-list">
                  <li className="home__active-item">
                    {intl.formatMessage({ id: "home-active-key-one" })}
                  </li>
                  <li className="home__active-item">
                    {intl.formatMessage({ id: "home-active-key-two" })}
                  </li>
                  <li className="home__active-item">
                    {intl.formatMessage({ id: "home-active-key-three" })}
                  </li>
                  <li className="home__active-item">
                    {intl.formatMessage({ id: "home-active-key-four" })}
                  </li>
                  <li className="home__active-item">
                    {intl.formatMessage({ id: "home-active-key-five" })}
                  </li>
                  <li className="home__active-item">
                    {" "}
                    {intl.formatMessage({ id: "home-active-key-six" })}
                  </li>
                  <li className="home__active-item">
                    {intl.formatMessage({ id: "home-active-key-seven" })}
                  </li>
                </ul>
                <div className="home__active-actions">
                  <Link to="/contact" className="home__active-button">
                    {intl.formatMessage({ id: "home-hero-cta" })}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="home__time">
          <div className="home__container">
            <h2 className="home__time-title">
              {intl.formatMessage({ id: "home-time-title" })}{" "}
              <span className="home__time-title home__time-title--bold">
                {intl.formatMessage({ id: "home-time-title2" })}
              </span>
            </h2>
            <div className="home__time-grid">
              <div className="home__time-box">
                <p className="home__time-text">
                  {intl.formatMessage({ id: "home-time-text" })}
                </p>
              </div>
              <div className="home__time-box">
                <img src={Time} alt="Time" className="home__time-image" />
              </div>
            </div>
          </div>
        </section>
        <section className="home__needs">
          <div className="home__container">
            <h2 className="home__needs-title">
              {intl.formatMessage({ id: "home-needs-title" })}
            </h2>
            <p className="home__needs-paragraph">
              {intl.formatMessage({ id: "home-needs-text" })}
            </p>
            <div className="home__needs-grid">
              <article className="home__needs-item">
                <img src={Needs2} alt="Needs" className="home__needs-image" />
                <h3 className="home__needs-name">
                  {intl.formatMessage({ id: "home-needs-name-two" })}
                </h3>
                <p className="home__needs-text">
                  {intl.formatMessage({ id: "home-needs-text-two" })}
                </p>
              </article>
              <article className="home__needs-item">
                <img src={Needs3} alt="Needs" className="home__needs-image" />
                <h3 className="home__needs-name">
                  {intl.formatMessage({ id: "home-needs-name-three" })}
                </h3>
                <p className="home__needs-text">
                  {intl.formatMessage({ id: "home-needs-text-three" })}
                </p>
              </article>
              <article className="home__needs-item">
                <img src={Needs4} alt="Needs" className="home__needs-image" />
                <h3 className="home__needs-name">
                  {intl.formatMessage({ id: "home-needs-name-four" })}
                </h3>
                <p className="home__needs-text">
                  {intl.formatMessage({ id: "home-needs-text-four" })}
                </p>
              </article>
            </div>
          </div>
        </section>
        <section className="home__platform">
          <div className="home__container">
            <h2 className="home__platform-title">
              {intl.formatMessage({ id: "home-platform-title" })}
              <span className="home__platform-title home__platform-title--bold">
                {intl.formatMessage({ id: "home-platform-title2" })}
              </span>
              SIRIX
            </h2>
            <div className="home__platform-grid">
              <div className="home__platform-box">
                <img
                  src={Computer2}
                  alt="Computer"
                  className="home__platform-image"
                />
              </div>
              <div className="home__platform-box">
                <p className="home__platform-text">
                  {intl.formatMessage({ id: "home-platform-text" })}
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="home__operate">
          <div className="home__container">
            <h2 className="home__operate-title">
              {intl.formatMessage({ id: "home-operate-title" })}
            </h2>
            <div className="home__operate-grid">
              <article className="home__operate-item">
                <img
                  src={Operate1}
                  alt="Operate"
                  className="home__operate-image"
                />
                <p className="home__operate-text">
                  <span className="home__operate-text home__operate-text--bold">
                    {intl.formatMessage({ id: "home-operate-name-one" })}
                  </span>

                  {intl.formatMessage({ id: "home-operate-text-one" })}
                </p>
              </article>
              <article className="home__operate-item">
                <img
                  src={Operate2}
                  alt="Operate"
                  className="home__operate-image"
                />
                <p className="home__operate-text">
                  <span className="home__operate-text home__operate-text--bold">
                    {intl.formatMessage({ id: "home-operate-name-two" })}
                  </span>

                  {intl.formatMessage({ id: "home-operate-text-two" })}
                </p>
              </article>
              <article className="home__operate-item">
                <img
                  src={Operate3}
                  alt="Operate"
                  className="home__operate-image"
                />
                <p className="home__operate-text">
                  <span className="home__operate-text home__operate-text--bold">
                    {intl.formatMessage({ id: "home-operate-name-three" })}
                  </span>

                  {intl.formatMessage({ id: "home-operate-text-three" })}
                </p>
              </article>
              <article className="home__operate-item">
                <img
                  src={Operate4}
                  alt="Operate"
                  className="home__operate-image"
                />
                <p className="home__operate-text">
                  <span className="home__operate-text home__operate-text--bold">
                    {intl.formatMessage({ id: "home-operate-name-four" })}
                  </span>

                  {intl.formatMessage({ id: "home-operate-text-four" })}
                </p>
              </article>
            </div>
          </div>
        </section>
        <div className="home__comments">
          <div className="home__container">
            <h2 className="home__comments-title">
              {intl.formatMessage({ id: "home-clients-title" })}
              <span className="home__comments-title home__comments-title--light">
                {intl.formatMessage({ id: "home-clients-title2" })}
              </span>
            </h2>
            <div className="home__comments-swiper">
              <Swiper
                modules={[Pagination, A11y]}
                spaceBetween={50}
                slidesPerView={1}
                direction={"vertical"}
                pagination={{ clickable: true }}
                onSwiper={swiper => console.log(swiper)}
                onSlideChange={() => console.log("slide change")}
              >
                <SwiperSlide>
                  <article className="home__comments-item">
                    <p className="home__comments-text">
                      {intl.formatMessage({ id: "home-clients-review" })}
                    </p>
                    <p className="home__comments-text home__comments-text--bold">
                      Esteban Flores
                    </p>
                  </article>
                </SwiperSlide>
                <SwiperSlide>
                  <article className="home__comments-item">
                    <p className="home__comments-text">
                      {intl.formatMessage({ id: "home-clients-review2" })}
                    </p>
                    <p className="home__comments-text home__comments-text--bold">
                      Gerardo Peralta
                    </p>
                  </article>
                </SwiperSlide>
                <SwiperSlide>
                  <article className="home__comments-item">
                    <p className="home__comments-text">
                      {intl.formatMessage({ id: "home-clients-review3" })}
                    </p>
                    <p className="home__comments-text home__comments-text--bold">
                      Eva Sánchez
                    </p>
                  </article>
                </SwiperSlide>
              </Swiper>
            </div>
            <TrustPilotWidget />
          </div>
        </div>
        <section className="home__start">
          <div className="home__container">
            <h2 className="home__start-title">
              {intl.formatMessage({ id: "home-start-title" })}
              <span className="home__start-title home__start-title--bold">
                {intl.formatMessage({ id: "home-start-title2" })}
              </span>
            </h2>
            <div className="home__start-grid">
              <article className="home__start-item">
                <img src={Step1} alt="" className="home__start-image" />
                <p className="home__start-text">
                  <span className="home__start-text home__start-text--bold">
                    {intl.formatMessage({ id: "home-start-name-one" })}
                  </span>
                  {intl.formatMessage({ id: "home-start-text-one" })}{" "}
                </p>
              </article>
              <article className="home__start-item">
                <img src={Step2} alt="" className="home__start-image" />
                <p className="home__start-text">
                  <span className="home__start-text home__start-text--bold">
                    {intl.formatMessage({ id: "home-start-name-two" })}
                  </span>
                  {intl.formatMessage({ id: "home-start-text-two" })}{" "}
                </p>
              </article>
              <article className="home__start-item">
                <img src={Step3} alt="" className="home__start-image" />
                <p className="home__start-text">
                  <span className="home__start-text home__start-text--bold">
                    {intl.formatMessage({ id: "home-start-name-three" })}
                  </span>
                  {intl.formatMessage({ id: "home-start-text-three" })}{" "}
                </p>
              </article>
            </div>
            <div className="home__start-actions">
              <Link to="/contact" className="home__start-button">
                {intl.formatMessage({ id: "home-hero-cta" })}
              </Link>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}
