import React, { useEffect, useRef } from "react";
import { useIntl } from "gatsby-plugin-intl";

const TrustBox = () => {
  const ref = useRef(null);
  const { locale } = useIntl();

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  
  console.log({ locale });

  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale={locale}
      data-template-id="5419b6a8b0d04a076446a9ad"
      data-businessunit-id="61b92a1aca273b0bc80399c6"
      data-style-height="24px"
      data-style-width="100%"
      data-theme="light"
      data-stars="1,2,3,4,5"
      data-no-reviews="hide"
      data-scroll-to-list="true"
      data-allow-robots="true"
    >
      <a href="https://www.trustpilot.com/review/fxmundo.net" target="_blank" rel="noopener">Trustpilot</a>
    </div>
  );
};

export default TrustBox;